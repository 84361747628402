<template>
    <main class>
      <v-app class>
        <div>
          <v-alert
          v-model="alert"
          border="left"
          dark
          dismissible
          class="alert"
          :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
          >
            {{alertMessage}}
          </v-alert>
        </div>
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Add Personnel Data</h1>
              </div>

              <div class="w-100">
                <v-expansion-panels multiple class="mb-5">
                  <v-expansion-panel>
                    <!-- Personnel  data form section  -->
                    <v-expansion-panel-header>Personnel Data</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="personnelForm" v-model="valid">
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <TextField v-model="formData.FirstName" :label="'Firstname'" />
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <TextField v-model="formData.MiddleName" :validate="false" :label="'Middlename'" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.LastName" :label="'Lastname'" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.Email" :type="'email'" :label="'EMAIL'" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField
                              v-model="formData.Mobile"
                              :type="'number'"
                              :label="'Phonenumber'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <!-- <TextField
                              v-model="formData.DateOfBirth"
                              :type="'date'"
                              :label="'Dath of Birth'"
                            /> -->
                            <v-text-field
                              v-model="formData.DateOfBirth"
                              outlined
                              label="Date of Birth"
                              @blur="DateOfBirthSelected"
                              dense
                              type="date"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.Cai" :validate="false" :label="'CAI'" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="AnnualSalaryAmount" :disable="true" :validate="false" :label="'Annual Salary'" />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Personnel  data form section end  -->

                  <!-- Employee data form section   -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>Employment Data</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="employmentForm" v-model="valid">
                        <v-row dense>
                          <v-col cols="12" sm="6">
                            <SelectField
                              :items="contractEmployer"
                              v-model="formData.ContractEmployerId"
                              :label="'Contractor Employer'"
                              :itemText="'name'"
                              :itemValue="'id'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <SelectField
                              :items="workAreas"
                              v-model="formData.WorkAreaId"
                              :label="'Work Area'"
                              :itemText="'name'"
                              :itemValue="'id'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <SelectField
                              :items="unionMembers"
                              v-model="formData.UnionMemberId"
                              :label="'Union Member'"
                              :itemText="'name'"
                              :itemValue ="'id'"
                              @onChange="unionMemberChange"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <SelectField
                              :items="jobTitles"
                              v-model="formData.JobTitleId"
                              :label="'Job Title'"
                              :itemText="'name'"
                              :itemValue="'id'"
                              @onChange="getSalaryAmount"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField
                              v-model="salaryAmount"
                              :disable="true"
                              :label="'Salary Amount'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField
                              v-model="formData.ResumptionDate"
                              :type="'date'"
                              :label="'Date Of Employment'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField
                              v-model="formData.RetirementDate"
                              :disable="true"
                              :label="'Date Of Retirement'"
                            />
                          </v-col>
                           <v-col cols="12" sm="6">
                            <v-autocomplete
                              :items="chargeCode"
                              v-model="formData.chargeCode"
                              outlined
                              dense
                              item-text="costCenter"
                              label="Charge Code"
                              item-value="costCenter"
                            ></v-autocomplete>

                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Supervisor"
                              v-model="supervisorName"
                              :append-icon="'mdi-magnify'"
                              @click:append="supervisorSearch = true"
                              @click.prevent="supervisorSearch = true"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Alternate Supervisor"
                              v-model="altSupervisorName"
                              :append-icon="'mdi-magnify'"
                              @click:append="altSupervisorSearch = true"
                              @click.prevent="altSupervisorSearch = true"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Superintendent"
                              v-model="SuperintendentName"
                              :append-icon="'mdi-magnify'"
                              @click:append="superintendentSearch = true"
                              @click.prevent="superintendentSearch = true"
                            />
                            <!-- <v-btn class="btn-style justify-start text-style" @click.prevent="openSuperintendentDialog"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{SuperintendentName == "" ?  "Superintendent" : SuperintendentName}}</v-btn> -->
                          </v-col>

                           <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Alternate Superintendent"
                              v-model="altSuperintendentName"
                              :append-icon="'mdi-magnify'"
                              @click:append="altSuperintendentSearch = true"
                              @click.prevent="altSuperintendentSearch = true"
                            />
                            <!-- <v-btn class="btn-style justify-start text-style" @click.prevent="openAltSuperintendentDialog"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{altSuperintendentName == "" ?  "Alternate Superintendent" : altSuperintendentName}}</v-btn> -->
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              label="GM"
                              type="text"
                              v-model="GMName"
                              :append-icon="'mdi-magnify'"
                              @click:append="gmSearch = true"
                              @click.prevent="gmSearch = true"
                            />
                            <!-- <v-btn class="btn-style justify-start text-style" @click.prevent="openGMDialog"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{GMName == "" ?  "GM" : GMName}}</v-btn> -->
                          </v-col>

                          <v-col cols="12" sm="6">
                            <SelectField
                              :items="departments"
                              v-model="formData.DepartmentId"
                              :label="'Department'"
                              :itemText="'name'"
                              :itemValue="'id'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <SelectField
                              :items="locations"
                              v-model="formData.LocationId"
                              :label="'Locations'"
                              :itemText="'name'"
                              :itemValue="'id'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <SelectField
                              :items="rotations"
                              v-model="formData.RotationId"
                              :label="'Rotations'"
                              :itemText="'name'"
                              :itemValue="'id'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-autocomplete
                              :items="orgId"
                              v-model="formData.orgId"
                              outlined
                              dense
                              :rules="[val => (val || '').length > 0 || 'This field is required']"
                              item-text="divisionName"
                              label="Division"
                              item-value="id"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Employee data form section  end  -->
                </v-expansion-panels>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <div class="btn-group">
                      <Button
                        :label="'Submit'"
                        :btnType="'Submit'"
                        @onClick="dialog = true"
                        :disabled="!valid"
                        :isLoading="isSavingUser"
                        class="mr-4"
                      />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                    </div>
                  </v-col>
                </v-row>
              </div>

              <PersonnelSearch :dialog="supervisorSearch" @selectPer="approver ($event,'SuperVisor')"  @close="supervisorSearch = false" :type="'employee'" :title="'Supervisor'"/>

              <PersonnelSearch :dialog="altSupervisorSearch" @selectPer="approver ($event,'AltSuperVisor')"  @close="altSupervisorSearch = false" :type="'employee'" :title="'Alternate Supervisor'"/>

              <PersonnelSearch :dialog="superintendentSearch" @selectPer="approver ($event,'Superintendent')"  @close="superintendentSearch = false" :type="'employee'" :title="'Superintendent'"/>

              <PersonnelSearch :dialog="altSuperintendentSearch" @selectPer="approver ($event,'AltSuperintendent')"  @close="altSuperintendentSearch = false" :type="'employee'" :title="'Alternate Superintendent'"/>

              <PersonnelSearch :dialog="gmSearch" @selectPer="approver ($event,'GM')"  @close="gmSearch = false" :type="'employee'" :title="'GM'"/>

               <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSavingUser" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
              <!-- dialog for supervisor end  -->
            </v-container>
          </div>
        </div>
      </v-app>
    </main>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { CREATE_EMPLOYEES } from '@/store/action-type'
import moment from 'moment'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'

import {
  departmentService,
  workAreaService,
  jobTitleService,
  locationService,
  contractEmployerService,
  nmaIntegrationService,
  employeeService,
  rotationService,
  unionMemberService
} from '@/services'
export default {
  components: {
    TextField,
    SelectField,
    Button,
    ConfirmationDialog,
    PersonnelSearch
  },
  inject: ['eventBus'],
  provide () {
    return {
      eventBus: this.eventBus
    }
  },
  data () {
    return {
      search: '',
      alertMessage: '',
      supervisorSearch: false,
      altSupervisorSearch: false,
      superintendentSearch: false,
      altSuperintendentSearch: false,
      gmSearch: false,
      alert: false,
      alertType: '',
      dialog: false,
      valid: false,
      formData: {
        Cai: '',
        FirstName: '',
        MiddleName: '',
        LastName: '',
        DateOfBirth: '',
        ResumptionDate: '',
        RetirementDate: '',
        Email: '',
        AnnualSalaryAmount: '',
        Mobile: '',
        ContractEmployerId: '',
        chargeCode: '',
        LocationId: '',
        DepartmentId: '',
        JobTitleId: '',
        RotationId: '',
        WorkAreaId: '',
        approvers: [],
        active: false,
        deleted: false,
        action: 'New',
        status: 0,
        addedBy: 'admin',
        dateAdded: '2020-08-23T00:00:00',
        lastModifiedDate: null,
        modifiedBy: 'admin',
        UnionMemberId: '',
        orgId: ''
      },
      supervisorLoading: false,
      superitendantLoading: false,
      altSupervisorLoading: false,
      altSuperintendentLoading: false,
      gmLoading: false,
      SuperintendentName: '',
      supervisorName: '',
      GMName: '',
      AnnualSalaryAmount: '',
      salaryAmount: 0,
      chargeCode: [],
      contractEmployer: [],
      supervisor: [],
      superitendant: [],
      GMS: [],
      departments: [],
      orgId: [],
      workAreas: [],
      jobTitles: [],
      locations: [],
      rotations: [
      ],
      isSavingUser: false,
      headers: [
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'emailAddress'
        }
      ],
      unionMembers: [],
      altSupervisorName: '',
      altSuperintendentName: '',
      organisationIds: []
    }
  },
  methods: {
    customFilter (item, queryText, itemText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.abbr.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },
    unionMemberChange (value) {
      const self = this
      jobTitleService.getJobTitleByUnion(value).then(result => {
        self.jobTitles = result.data
      })
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    getSalaryAmount (item) {
      const jobtitle = this.jobTitles.filter(title => title.id === item)
      this.formData.AnnualSalaryAmount = jobtitle[0].salaryAmount * 12
      this.AnnualSalaryAmount = this.formatMoney(jobtitle[0].salaryAmount * 12)
      this.salaryAmount = this.formatMoney(jobtitle[0].salaryAmount)
    },
    openSupervisorDialog () {
      this.$refs.Supervisordialog.toggleModal()
    },
    openAltSupervisorDialog () {
      this.$refs.AltSupervisordialog.toggleModal()
    },
    formatMoney (amount, decimalCount = 2, decimal = '.', thousands = ',') {
      try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
        let j = (i.length > 3) ? i.length % 3 : 0

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
      } catch (e) {
        console.log(e)
      }
    },
    openSuperintendentDialog () {
      this.$refs.Superintendentdialog.toggleModal()
    },
    openAltSuperintendentDialog () {
      this.$refs.AltSuperintendentdialog.toggleModal()
    },
    openGMDialog () {
      this.$refs.GMdialog.toggleModal()
    },
    salaryAmountValue () {

    },
    DateOfBirthSelected () {
      const date = moment(this.formData.DateOfBirth).format('DD-MM-YYYY')
      let newDate = moment(date, 'DD-MM-YYYY').add(60, 'years')
      this.formData.RetirementDate = newDate.format('MM-DD-YYYY')
    },
    approver (item, title) {
      let approverTitle = title
      let alternate = false
      if (title === 'SuperVisor') {
        this.search = ''
        this.supervisor = []
        this.supervisorName = item.name
      } else if (title === 'Superintendent') {
        this.search = ''
        this.superitendant = []
        this.SuperintendentName = item.name
      } else if (title === 'GM') {
        this.search = ''
        this.GMS = []
        this.GMName = item.name
      } else if (title === 'AltSuperVisor') {
        this.search = ''
        this.supervisor = []
        this.altSupervisorName = item.name
        approverTitle = 'SuperVisor'
        alternate = true
      } else if (title === 'AltSuperintendent') {
        this.search = ''
        this.supervisor = []
        this.altSuperintendentName = item.name
        approverTitle = 'Superintendent'
        alternate = true
      }

      const newApprovers = []
      if (this.formData.approvers.length > 0) {
        this.formData.approvers.forEach(item => {
          if (!(item.approverTitle === approverTitle && item.isAlternate === alternate)) {
            newApprovers.push(item)
          }
        })
      }

      newApprovers.push({
        approvercai: item.id,
        approverEmail: item.emailAddress,
        approverTitle: approverTitle,
        isAlternate: alternate
      })

      this.formData.approvers = []
      this.formData.approvers = newApprovers
    },
    superVisorSearchInput () {
      if (this.search === '') {
        this.supervisor = []
        return
      }

      this.supervisorLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.supervisor = result.data
      }).catch(error => {
        console.log('error', error)
      }).finally(() => {
        this.supervisorLoading = false
      })
    },
    SuperintendentInput () {
      if (this.search === '') {
        this.superitendant = []
        return
      }
      this.superitendantLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.superitendant = result.data
      }).catch(() => {
        this.showAlertMessage('Failed to Load Superintendent', 'error')
      }).finally(() => {
        this.superitendantLoading = false
      })
    },
    GMSearchInput () {
      if (this.search === '') {
        this.GMS = []
        return
      }
      this.gmLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.GMS = result.data
      }).catch(() => {
        this.showAlertMessage('Failed to Load GM', 'error')
      }).finally(() => {
        this.gmLoading = false
      })
    },
    onSave () {
      this.isSavingUser = true
      this.$store.dispatch(CREATE_EMPLOYEES, this.formData).then(result => {
        this.showAlertMessage('Personnel Data creation successful', 'success')
        this.isSavingUser = false
        this.onReset()
      }).catch(() => {
        this.showAlertMessage('Personnel Data creation failed', 'error')
      }).finally(() => {
        this.isSavingUser = false
        this.dialog = false
        this.onReset()
      })
    },
    closeSuperintendentDialog () {
      this.$refs.Superintendentdialog.closeModal()
    },
    closeSupervisorDialog () {
      this.$refs.Supervisordialog.closeModal()
    },
    closeGMDialog () {
      this.$refs.GMdialog.closeModal()
    },
    closeAltSuperintendentDialog () {
      this.$refs.AltSuperintendentdialog.closeModal()
    },
    closeAltSupervisorDialog () {
      this.$refs.AltSupervisordialog.closeModal()
    },
    onReset () {
      this.$refs.employmentForm.reset()
      this.$refs.personnelForm.reset()
      this.SuperintendentName = ''
      this.supervisorName = ''
      this.altSupervisorName = ''
      this.altSuperintendentName = ''
      this.GMName = ''
      this.superitendant = []
      this.supervisor = []
      this.GMS = []
      this.search = ''
    }
  },
  mounted () {
    const self = this
    departmentService.getAllDepartments().then((result) => {
      self.departments = result.data.items
    })

    workAreaService.getAllWorkArea().then((result) => {
      self.workAreas = result.data.items
    })

    contractEmployerService.getAllContractEmployers().then((result) => {
      self.contractEmployer = result.data.items
    })

    nmaIntegrationService
      .getAllChargeCodes()
      .then((result) => {
        self.chargeCode = result.data
      })
      .catch((error) => {
        console.log('errorChargecode', error)
      })
    nmaIntegrationService
      .getAllOrgId()
      .then((result) => {
        self.orgId = result.data
      })
      .catch((error) => {
        console.log('errorChargecode', error)
      })
    // jobTitleService
    //   .getAllJobTitles()
    //   .then((result) => {
    //     console.log(result.data.items)
    //     self.jobTitles = result.data.items
    //   })
    //   .catch((error) => {
    //     console.log('jobtitleservice', error)
    //   })

    locationService
      .getLocations()
      .then((result) => {
        self.locations = result.data.items
      })
      .catch((error) => {
        console.log('LocationService', error)
      })

    rotationService.getAllRotation().then((result) => {
      self.rotations = result.data.items
    }).catch(error => {
      console.log('RotationService', error)
    })

    unionMemberService.getAllUnionMembers().then(result => {
      self.unionMembers = result.data
    })

    employeeService.getOrganisationIds().then(result => {
      self.organisationIds = result.data
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.btn-style{
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.text-style{
  text-transform: capitalize;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
